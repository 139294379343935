
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const cacheStore = namespace('cache');

@Component({
	name: 'WebsiteFooter',
	components: {
		FooterQuicklinks: () => import('~/components/website/footer/FooterQuicklinks.vue'),
		FooterPartners: () => import('~/components/website/footer/FooterPartners.vue'),
		FooterSocialMedia: () => import('~/components/website/footer/FooterSocialMedia.vue'),
		FooterApps: () => import('~/components/website/footer/FooterApps.vue')
	},
	async fetch() {
		await this.$store.dispatch('cache/loadMarketingValues');
	}
})
export default class WebsiteFooter extends Vue {
	@cacheStore.State('marketingValues') marketingValues;

	@Prop({
		type: String,
		required: true,
		validator: (value: string) => ['user', 'company'].includes(value)
	})
	readonly styling!: string;
}
